import React from 'react';
import css from './Footer.module.css';
import cn from 'classnames';

export const Footer = () => {
  return <div className={css.footer}>
    <div className={css.footer_content}>
      <div className={cn(css.footer_col)} >
        <a
          className={cn(css.link, 'hide-on-mobile')}
          href="mailto:info@fabiobiondi.io">info@fabiobiondi.io</a>
      </div>


      <div className={cn(css.footer_col)} style={{ margin: 'auto'}}>

      </div>
      <div className={cn(css.footer_col)} >
        P.I. 01116230317
      </div>
    </div>
  </div>
};

