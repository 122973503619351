import React from 'react';
import ReactTooltip from 'react-tooltip'
import cn from 'classnames';

import css from './Timeline.module.css';
import { graphql, StaticQuery } from "gatsby"
import { Expo, TimelineMax, TweenMax } from "gsap/TweenMax"


export const thumbLogo = graphql`
  fragment thumbLogo on File {
    childImageSharp {
      fluid(maxWidth: 60) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

const TimeLines = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          ts: file(relativePath: { eq: "loghi/ts.png" }) { ...thumbLogo },
          js: file(relativePath: { eq: "loghi/js.png" }) { ...thumbLogo },
          angular2: file(relativePath: { eq: "loghi/angular2.png" }) { ...thumbLogo },
          react: file(relativePath: { eq: "loghi/react.png" }) { ...thumbLogo },
          ngrx: file(relativePath: { eq: "loghi/ngrx.png" }) { ...thumbLogo },
          rxjs: file(relativePath: { eq: "loghi/rx_js.png" }) { ...thumbLogo },
          redux: file(relativePath: { eq: "loghi/redux.png" }) { ...thumbLogo },
          flash: file(relativePath: { eq: "loghi/adobeflash.png" }) { ...thumbLogo },
          flex: file(relativePath: { eq: "loghi/adobeflex.png" }) { ...thumbLogo },
          air: file(relativePath: { eq: "loghi/adobeair.png" }) { ...thumbLogo },
          ionic: file(relativePath: { eq: "loghi/ionic.png" }) { ...thumbLogo },
          dthree: file(relativePath: { eq: "loghi/d3.png" }) { ...thumbLogo },
          angularjs: file(relativePath: { eq: "loghi/angularjs.png" }) { ...thumbLogo },
          createjs: file(relativePath: { eq: "loghi/createjs.png" }) { ...thumbLogo },
        }
      `}
      render={data => <TimeLinesContent data={data} />}
    />
  )
}

class TimeLinesContent extends React.Component {
  componentDidMount() {
    this.show();
  }

  show() {
    const tl = new TimelineMax();

    TweenMax.set(`.${css.timeline}`, { display: 'unset' })

    tl.add(
      TweenMax.to(`.${css.timeline}`, 0.60, {
        opacity: 1, ease: Expo.easeInOut, delay: 1,

      }, '-0.3')
    );

    tl.add(
      TweenMax.staggerFrom('.' + css.timeline_cell_logo, 1, {
        scale:0, opacity:0, rotation: 45, ease: Expo.easeInOut, force3D:true,
        stagger: { amount: -0.6 }
      })
    );

    tl.add(
      TweenMax.staggerFrom('.' + css.timeline_cell_year, 1, {
        scale:0, opacity:0, marginRight: 20, ease: Expo.easeInOut, force3D:true,
        stagger: { amount: -0.6 },
        onComplete: () => {
          /*TweenMax.set(`.${css.timeline}`, { clearProps: 'all' });*/
        }
      }, -1)
    );

  }

  render() {
    return (<div {...this.props} className={cn(css.timeline, this.props.className)} >

      <div className={css.timeline_flex_logo}>
        <div className={css.timeline_cell_logo}   >
        <span data-tip data-for='now' >
          <img alt="logo" src={this.props.data.ngrx.childImageSharp.fluid.src} className={css.timeline_logo}/>
          <img alt="logo" src={this.props.data.rxjs.childImageSharp.fluid.src} className={css.timeline_logo}/>
        </span>
        </div>
        <div className={css.timeline_cell_logo}>
        <span data-tip data-for='2016' >
          <img alt="logo" src={this.props.data.angular2.childImageSharp.fluid.src} className={css.timeline_logo}/>
          <img alt="logo" src={this.props.data.ts.childImageSharp.fluid.src} className={css.timeline_logo}/>
        </span>
        </div>
        <div className={css.timeline_cell_logo}>
        <span data-tip data-for='2014' >
          <img alt="logo" src={this.props.data.react.childImageSharp.fluid.src} className={css.timeline_logo}/>
          <img alt="logo" src={this.props.data.redux.childImageSharp.fluid.src} className={css.timeline_logo}/>
          <img alt="logo" src={this.props.data.dthree.childImageSharp.fluid.src} className={cn(css.timeline_logo, 'hide-on-mobile')}/>
        </span>
        </div>
        <div className={css.timeline_cell_logo} data-tip="happyFace">
        <span data-tip data-for='2010' >
          <img alt="logo" src={this.props.data.angularjs.childImageSharp.fluid.src} className={css.timeline_logo}/>
          <img alt="logo" src={this.props.data.ionic.childImageSharp.fluid.src} className={cn(css.timeline_logo, 'hide-on-mobile')}/>
          <img alt="logo" src={this.props.data.js.childImageSharp.fluid.src} className={css.timeline_logo}/>
        </span>
        </div>
        <div className={css.timeline_cell_logo}>
        <span data-tip data-for='2004' >
          <img alt="logo" src={this.props.data.flash.childImageSharp.fluid.src} className={css.timeline_logo}/>
          <img alt="logo" src={this.props.data.flex.childImageSharp.fluid.src} className={css.timeline_logo}/>
          <img alt="logo" src={this.props.data.air.childImageSharp.fluid.src} className={cn(css.timeline_logo, 'hide-on-mobile')}/>
        </span>
        </div>
      </div>
      <div className={css.timeline_flex_year}>
        <div className={css.timeline_cell_year}>NOW</div>
        <div className={css.timeline_cell_year}>2016</div>
        <div className={css.timeline_cell_year}>2014</div>
        <div className={css.timeline_cell_year}>2010</div>
        <div className={css.timeline_cell_year}>2004</div>
      </div>

      <ReactTooltip id='now' >
        <span>I improved my knowledge about data architectures, <br />state managers and reactive programming</span>
      </ReactTooltip>

      <ReactTooltip id='2016' >
        <span>I'm using Angular (2+) since its alpha version <br />and I organize training courses, <br />events and meetups throughout Italy</span>
      </ReactTooltip>

      <ReactTooltip id='2014' >
        <span>I loved React and Redux at first glance<br />and used in several projects <br />with D3.js and HTML Canvas together</span>
      </ReactTooltip>

      <ReactTooltip id='2010' >
        <span>I started using Javascript in 2010 <br />and developed dozens of projects<br /> by using AngularJS (versions 0.8 / 1.5), <br />Ionic 1 and HTML canvas</span>
      </ReactTooltip>

      <ReactTooltip id='2004' >
        <span>I have developed hundreds of projects <br/>by using the Adobe Flash Platform.<br/>I was a an Adobe Certified Instructor<br/> in Flash, Flex, AIR, Flash Catalyst</span>
      </ReactTooltip>
    </div>)
  }


};

export default TimeLines;
