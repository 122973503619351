import React from 'react';
import PropTypes from 'prop-types';
import { Expo, TimelineMax, TweenMax } from "gsap/TweenMax"
import '../../../../node_modules/font-awesome/css/font-awesome.min.css';
import TimeLines from "../ui/TimeLines"
import css from './Home1.module.css';
import cn from 'classnames';
import { Footer } from "../../../core/Footer"

export default class Home1 extends React.Component {
  componentDidMount() {
    this.show();
  }
  show = () => {
    const tl = new TimelineMax();
    // tl.eventCallback("onComplete", () => console.log('comple init')); //sets the onComplete

    /*tl.from(`.${css.main_menu_wrapper}`, 0.3, {opacity:0});*/

    tl.add(
      TweenMax.set('.page_title_xxl_wrapper', { marginLeft: 0, delay: 0.3})
    )

    tl.add(
      TweenMax.staggerFrom('.title_xxxl', 0.3, {
        delay: 0.5, scale:1.5, opacity:0, ease: Expo.easeInOut, force3D:true
      }, 0.1)
    );


    tl.add(
      TweenMax.staggerFrom('.comma_for_ani', 0.5, {
        opacity:0, ease: Expo.easeInOut, force3D:true,
        stagger: {amount: 0.3}
      })
    );
  }


  render() {
    return <div>
      <div className={cn('page_title_xxl_wrapper', css.text_wrapper )} style={{marginLeft: -600}}>
        <h1 className="title_xxxl text-shadow-xxl">Trainer<span className="color-js">
          <span className="comma_for_ani">,</span>
        </span></h1>
        <h2 className="title_xxxl text-shadow-xxl">Speaker<span className="color-react">
          <span className="comma_for_ani">,</span>
        </span></h2>
        <h2 className="title_xxxl text-shadow-xxl">Developer<span className="color-angular">
          <span className="comma_for_ani">.</span>
        </span></h2>

        {/*<button onClick={() => this.props.onGotoPage(1)}>prev</button>*/}
      </div>
      <TimeLines />
      <Footer></Footer>

    </div>
  }
  componentWillUnmount() {
    TweenMax.killTweensOf('.title_xxxl');
    TweenMax.killTweensOf('.timeline');
  }
}

Home1.propTypes = {
  onGotoPage: PropTypes.func,
}
